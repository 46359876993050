.chart-block.now-in-view,
.zoomInStable {
  animation-name: zoomInStable;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: zoomInStable;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoomInStable;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoomInStable;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoomInStable;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: 1;
  -o-animation-timing-function: linear;
}

.zoomInStable2 {
  animation-name: zoomInStable;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoomInStable;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoomInStable;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoomInStable;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoomInStable;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.slideInUp2 {
  animation-name: slideInUp;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: slideInUp;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: slideInUp;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;

  -ms-animation-name: slideInUp;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.app-download-section .big-shape-14.now-in-view,
.slideInRight2 {
  animation-name: slideInRight;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: slideInRight;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: slideInRight;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;

  -ms-animation-name: slideInRight;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.slideInLeft2 {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: slideInLeft;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;

  -ms-animation-name: slideInLeft;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.banner-section-one .shape-29.now-in-view,
.slideInDown2 {
  animation-name: slideInDown;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: slideInDown;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: slideInDown;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;

  -ms-animation-name: slideInDown;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 1;
  }
}
@-moz-keyframes rotateme {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -moz-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 1;
  }
}
@-o-keyframes rotateme {
  0% {
    -o-transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -o-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -o-transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes rotateme {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.service-block-one .inner-box .icon .round-shape,
.rotate-me {
  animation-name: rotateme;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: rotateme;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: rotateme;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: rotateme;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

/* Bob Up Down*/
@-webkit-keyframes float_up_down {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes float_up_down {
  0% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.banner-section-two .shape-15 {
  animation-name: float_up_down;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: float_up_down;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: float_up_down;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: float_up_down;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: float_left_right;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float_up_down_two {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
    transform: rotateX(0deg) translateY(15px);
  }
  to {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@keyframes float_up_down_two {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
    transform: rotateX(0deg) translateY(15px);
  }
  to {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

.float-up-down-two {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: float_up_down_two;
  animation-name: float_up_down_two;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

/* Bob */
@-webkit-keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

.circle-animation {
  animation-name: circle_animation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: circle_animation;
  -webkit-animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: circle_animation;
  -moz-animation-duration: 30s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: circle_animation;
  -ms-animation-duration: 30s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: circle_animation;
  -o-animation-duration: 30s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@keyframes circle_animation {
  0% {
    transform: translate(165px, -179px);
  }
  100% {
    transform: translate(-346px, 617px);
  }
}

@-webkit-keyframes circle_animation {
  0% {
    -webkit-transform: translate(165px, -179px);
  }
  100% {
    -webkit-transform: translate(-346px, 617px);
  }
}

.banner-section-three .shape-18,
.sec-title .shape-icon,
.subscribe-section-two .icon-buildings,
.subscribe-section .icon-buildings,
.video-section .icon-buildings {
  animation-name: float_left_right;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: float_left_right;
  -webkit-animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: float_left_right;
  -moz-animation-duration: 30s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: float_left_right;
  -ms-animation-duration: 30s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: float_left_right;
  -o-animation-duration: 30s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float_left_right_two {
  0% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0px);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }
}

@keyframes float_left_right_two {
  0% {
    transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    transform: translateX(0px);
    opacity: 0.8;
  }

  100% {
    transform: translateX(-30px);
    opacity: 1;
  }
}

.banner-section-five .drone,
.banner-section-two .shape-12,
.icon-mountain {
  animation-name: float_left_right_two;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: float_left_right_two;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: float_left_right_two;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: float_left_right_two;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: float_left_right_two;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.banner-section-two .shape-13,
.testimonial-section .shape-7,
.banner-section-one .video-box .video-link .icon,
.zoomFade {
  animation-name: zoom-fade;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes zoom-fade-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

.pricing-table .shape-17,
.integrations-block .anim-icons .icon {
  animation-name: zoom-fade-two;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade-two;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade-two;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade-two;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade-two;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
